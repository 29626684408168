<div style="height:100px"><img src="assets/image/favicon/favicon_logo.png" alt="AllaiHealth"/></div>
<div class=""><h2 class="dci-header">DCI Manager {{ this.getEnvironmentDisplay() }}</h2></div>

<div class="buttons">
	@if (!loginPage) {
		<button mat-button (click)="gotoDigitalClinicalInterviewss()">Surveys</button>
		<button mat-button (click)="onLogout()">Logout</button>
	}
</div>

