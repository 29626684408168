<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="survey-tree">
	<!-- This is the tree node template for leaf nodes -->
	<!-- There is inline padding applied to this node using styles.
	This padding value depends on the mat-icon-button width. -->
	<mat-nested-tree-node *matTreeNodeDef="let node;" >
		<div class="mat-tree-node allai" >
			<button mat-icon-button matTreeNodeToggle
					[attr.aria-label]="'Toggle ' + node.name">
				<mat-icon class="mat-icon-rtl-mirror">
					{{ this.getLeafIcon(node)}}
				</mat-icon>
			</button>
			<span (click)="onContextMenu($event,node)" [matMenuTriggerFor]="contextMenu" >{{node.name}}</span>
		</div>
		<!-- There is inline padding applied to this div using styles.
			This padding value depends on the mat-icon-button width.  -->
		<div [class.survey-tree-invisible]="!treeControl.isExpanded(node)"
			role="group">
			<ng-container matTreeNodeOutlet></ng-container>
		</div>
	</mat-nested-tree-node>
	<!-- This is the tree node template for expandable nodes -->
	<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
		<div class="mat-tree-node allai" >
			<button mat-icon-button matTreeNodeToggle
					[attr.aria-label]="'Toggle ' + node.name">
				<mat-icon class="mat-icon-rtl-mirror">
					{{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
				</mat-icon>
			</button>
			<span  (click)="onContextMenu($event,node)"  [matMenuTriggerFor]="contextMenu">{{node.name}}</span>
		</div>
		<!-- There is inline padding applied to this div using styles.
			This padding value depends on the mat-icon-button width.  -->
		<div [class.survey-tree-invisible]="!treeControl.isExpanded(node)"
			role="group">
			<ng-container matTreeNodeOutlet></ng-container>
		</div>
	</mat-nested-tree-node>
</mat-tree>

<mat-menu #contextMenu="matMenu" overlapTrigger="true">
	<ng-template matMenuContent let-item="item">
		<button mat-menu-item (click)="createNewFolder($event)">New Folder</button>
		<button mat-menu-item (click)="createNewDigitalClinicalInterview($event)">New DCI</button>
		<button mat-menu-item (click)="openDigitalClinicalInterview()">Open DCI</button>
		<button mat-menu-item (click)="rename($event)">Rename</button>
		<button mat-menu-item (click)="copy()">Copy</button>
		<button mat-menu-item (click)="paste()">Paste</button>
	</ng-template>
</mat-menu>
<mat-progress-spinner [diameter]="50" class="spinner" mode="indeterminate" *ngIf="loading"></mat-progress-spinner>
