import { Component, ViewChild, inject } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideNativeDateAdapter } from "@angular/material/core";
import { DciManagerService } from "../../services/dci-manager.service";
import { DigitalClinicalInterviewNode } from "dci-manager-shared/dist/util/digital-clinical-interview";
import { Router } from "@angular/router";
import { MatIconModule } from '@angular/material/icon';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SessionService } from '../../services/session.service';
import { InputTextDialog } from '../../dialog/input-text-dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-get-surveys',
    standalone: true,
    providers: [provideNativeDateAdapter()],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatTreeModule,
        MatIconModule,
        MatMenuTrigger,
        MatMenuModule,
        MatProgressSpinnerModule,
        CommonModule
    ],
    templateUrl: './get-surveys.component.html',
    styleUrl: './get-surveys.component.scss'
})

export class GetSurveysComponent {

    hasChild = (_: number, node: DigitalClinicalInterviewNode) => !!node.children && node.children.length > 0;

    root: DigitalClinicalInterviewNode;
    treeControl = new NestedTreeControl<DigitalClinicalInterviewNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<DigitalClinicalInterviewNode>();

    @ViewChild(MatMenuTrigger)
    contextMenu: MatMenuTrigger;

    currentNode: DigitalClinicalInterviewNode;
    copiedNode: DigitalClinicalInterviewNode;
    loading: boolean;

    dialog = inject(MatDialog);

    constructor(private router: Router, private dciManagerService: DciManagerService, private sessionService: SessionService) {
        this.loadDigitalClinicalInterviewNodes();
    }

    async loadDigitalClinicalInterviewNodes(id?: string) {
        this.loading = true;
        await this.dciManagerService.loadDigitalClinicalInterviews();
        let surveys = this.dciManagerService.digitalClinicalInterviews;
        this.root = { name: "Digital Clinical Interviews" };
        let rootChildren: DigitalClinicalInterviewNode[] = [];
        this.root.children = rootChildren;

        surveys.forEach((value) => this.addNode({
            name: value.name,
            digitalClinicalInterview: value
        }));
        let data = [];
        data.push(this.root);
        this.dataSource.data = data;
        this.treeControl.expand(this.root);
        if (id) {
            this.expandNode(this.root, id);
        }
        this.loading = false;
    }

    private expandNode(node: DigitalClinicalInterviewNode, id: string) {
        if (node.digitalClinicalInterview?.id == id) {
            this.expandParents(node);
            return;
        }
        if (node.children) {
            node.children.forEach(
                child => this.expandNode(child, id)
            );
        }
    }
    private expandParents(node: DigitalClinicalInterviewNode): void {
        if (node.parent) {
            this.expandParents(node.parent);
        }
        this.treeControl.expand(node);
    }


    private addNode(node: DigitalClinicalInterviewNode): void {
        let path = node.digitalClinicalInterview.folder.split("/");
        let currentDirectory = this.root;
        path.forEach((directoryName) => {
            if (directoryName) {
                let directory = this.getDirectory(currentDirectory, directoryName)
                if (directory == null) {
                    directory = {
                        name: directoryName,
                        children: []
                    }
                    currentDirectory.children.push(directory);
                    directory.parent = currentDirectory;
                }
                currentDirectory = directory;
            }
        })
        node.parent = currentDirectory;
        currentDirectory.children.push(node);
    }

    private getDirectory(node: DigitalClinicalInterviewNode, directoryName: string): DigitalClinicalInterviewNode {
        let returnValue = null;
        node.children?.forEach((child) => {
            if (!child.digitalClinicalInterview && child.name == directoryName) {
                returnValue = child;
            }
        });
        return returnValue;
    }

    private refreshTree(node?: DigitalClinicalInterviewNode): void {
        let data = this.dataSource.data;
        this.dataSource.data = null;
        this.dataSource.data = data;
        if (node) {
            this.treeControl.expand(this.root);
        }
    }

    private getPath(parent: DigitalClinicalInterviewNode): string {
        let currentNode = parent;
        let path = '';
        while (currentNode && currentNode != this.root) {
            path = "/" + currentNode.name + path;
            currentNode = currentNode.parent;
        }
        return path;
    }

    public getLeafIcon(node: DigitalClinicalInterviewNode) {
        if (!!node.digitalClinicalInterview) {
            return 'wysiwyg';
        }
        return 'folder';
    }

    public onContextMenu(event: MouseEvent, node: DigitalClinicalInterviewNode) {
        this.currentNode = node;
        event.preventDefault();
    }

    public createNewDigitalClinicalInterview(event: MouseEvent) {
        let ref = this.openDialog(event,"New DCI","Name");

        ref.afterClosed().subscribe(result => {
            let name = ref.componentInstance.inputValue;
            let cancelled = ref.componentInstance.cancelled;

            if (name && !cancelled) {
                let parent = this.currentNode;
                if (!!parent.digitalClinicalInterview) {
                    parent = this.currentNode.parent;
                }
                this.newDigitalClinicalInterview(name, parent)
            }
        });
    }

    async newDigitalClinicalInterview(name: string, parent: DigitalClinicalInterviewNode) {
        this.loading = true;
        let path = this.getPath(parent);
        let response = await this.dciManagerService.addDigitalClinicalInterview(name, path, this.sessionService.getUserName());
        this.loadDigitalClinicalInterviewNodes(response.id);
    }

    public createNewFolder(event: MouseEvent) {
        let ref = this.openDialog(event,"New Folder","Name");

        ref.afterClosed().subscribe(result => {
            let name = ref.componentInstance.inputValue;
            let cancelled = ref.componentInstance.cancelled;
            if (name && !cancelled) {
                let parent = this.currentNode;
                if (!!parent.digitalClinicalInterview) {
                    parent = this.currentNode.parent;
                }
                let children: DigitalClinicalInterviewNode[] = [];
                let node = {
                    name: name,
                    children: children,
                    parent: parent
                };
                parent.children.push(node);
                this.refreshTree();
                this.treeControl.expand(parent);
            }
        });
    }

    public openDigitalClinicalInterview() {
        if (this.currentNode.digitalClinicalInterview) {
            this.router.navigate(['/dci', this.currentNode.digitalClinicalInterview.id]);
        }
    }

    async rename(event: MouseEvent) {
        let ref = this.openDialog(event,`Rename ${this.getCurrentNodeType()}`,"New Name",this.currentNode.name);

        ref.afterClosed().subscribe(result => {
            let newName = ref.componentInstance.inputValue;
            let cancelled = ref.componentInstance.cancelled;
            if (newName && newName !== this.currentNode.name && !cancelled) {
                this.currentNode.name = newName;
                this.loading = true;
                if (this.currentNode.digitalClinicalInterview) {
                    this.currentNode.digitalClinicalInterview.name = newName;
                    this.dciManagerService.updateDigitalClinicalInterview(this.currentNode.digitalClinicalInterview, this.sessionService.getUserName())
                        .then(value =>
                            this.loadDigitalClinicalInterviewNodes(this.currentNode.digitalClinicalInterview.id)
                        );
                } else {
                    this.updatePaths().then(
                        value => {
                            this.loadDigitalClinicalInterviewNodes(this.currentNode.children[0].digitalClinicalInterview.id);
                        }
                    )
                }
            }
        });
    }

    async updatePaths() {
        let promises: Promise<any>[] = [];
        this.currentNode.children.forEach(child => this.updateFolderPath(child, promises));
        await Promise.all(promises);
    }

    async updateFolderPath(node: DigitalClinicalInterviewNode, promises: Promise<any>[]) {
        let path = this.getPath(node.parent);
        if (node.digitalClinicalInterview) {
            node.digitalClinicalInterview.folder = path;
            promises.push(this.dciManagerService.updateDigitalClinicalInterview(node.digitalClinicalInterview, this.sessionService.getUserName()));
        } else if (node.children) {
            node.children.forEach(child => this.updateFolderPath(child, promises));
        }

    }

    public copy() {
        this.copiedNode = this.currentNode;
    }

    public paste() {
        this.copyDigitalClinicalInterview()
    }

    async copyDigitalClinicalInterview() {
        this.loading = true;
        let path = this.currentNode.digitalClinicalInterview ? this.getPath(this.currentNode.parent) : this.getPath(this.currentNode);
        let response = await this.dciManagerService.addDigitalClinicalInterviewWithJson(`${this.copiedNode.name} Copy`, path, this.sessionService.getUserName(), this.copiedNode.digitalClinicalInterview.id);
        this.loadDigitalClinicalInterviewNodes(response.id);
    }

    public openDialog(event: MouseEvent, title: string, question: string, value?: string): MatDialogRef<InputTextDialog, any> {

        console.log("EVENT: ", event);
        let ref = this.dialog.open(InputTextDialog, {
            data: {
                title: title,
                question: question
            },
            width: '400px',
            height: '175px',
            position: {
                left: event.x + 'px',
                top: (event.y) + 'px'
            },
            autoFocus: true
        });
        ref.componentInstance.setValue(value);
        return ref;
    }

    private getCurrentNodeType(): string {
        return this.getNodeType(this.currentNode);
    }
    private getNodeType(node: DigitalClinicalInterviewNode): string {
        return node.digitalClinicalInterview? "DCI": "Folder";
    }

    private isCurrentNodeFolder(): boolean {
        return this.isFolder(this.currentNode);
    }

    private isFolder(node: DigitalClinicalInterviewNode): boolean {
        return !this.newDigitalClinicalInterview;
    }

}
