import {Component} from '@angular/core';
import {MatExpansionModule} from "@angular/material/expansion";
import {GetSurveysComponent} from "./get-surveys/get-surveys.component";

/** Non-login pages on the site */
@Component({
	selector: 'app-main',
	standalone: true,
	imports: [
		MatExpansionModule,
		GetSurveysComponent
	],
	templateUrl: './main.component.html',
	styleUrl: './main.component.scss'
})
export class MainComponent {

}
