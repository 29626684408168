import {inject, Injectable} from '@angular/core';
import {CanActivateFn, Router} from "@angular/router";
import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession} from "amazon-cognito-identity-js";
import {environment} from "../../environments/environment";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

/** Handle authorization and authentication with Cognito and the backend */
@Injectable({
	providedIn: 'root'
})
export class SessionService implements HttpInterceptor {
	/** User pool to use */
	private readonly userPool:CognitoUserPool;

	/** The loaded user */
	private user:CognitoUser;

	/** The loaded user's session */
	private session:CognitoUserSession;

	constructor(private router:Router) {
		//set up the pool
		this.userPool = new CognitoUserPool({
			UserPoolId: environment.cognitoUserPoolId,
			ClientId: environment.cognitoClientId
		});
	}

	/** Login the user */
	public login(email:string, password:string) {
		return new Promise<void>((resolve, reject) => {
			//set up a new user
			const newUser = new CognitoUser({
				Username: email,
				Pool: this.userPool
			});

			//set the auth details
			const details = new AuthenticationDetails({
				Username: email,
				Password: password
			});

			//authenticate them and if successful set the user and session
			newUser.authenticateUser(details, {
				onSuccess: session => {
					this.user = newUser;
					this.session = session;
					resolve();
				},
				onFailure: err => {
					this.user = null;
					this.session = null;
					console.log("Login error", err);
					reject(err);
				}
			})
		});
	}

	/** Get a stored session from local storage */
	private getSession() {
		return new Promise<void>((resolve, reject) => {
			const cognitoUser = this.userPool.getCurrentUser();

			if(cognitoUser !== null) {
				cognitoUser.getSession((error:Error, session:CognitoUserSession) => {
					if(error) {
						reject(error);
					} else {
						this.user = cognitoUser;
						this.session = session;
						resolve();
					}
				});
			}

			reject("No Session");
		})
	}

	/** Is the user logged in? Or has a stored session */
	private async canActivate() {
		if(this.hasSession) {
			return true;
		}

		try {
			await this.getSession();
			return true;
		} catch(e) {
			return this.router.createUrlTree(["login"]);
		}
	}

	/** Logout the current user and redirect to login */
	logout():void {
		if(this.user) {
			this.user.signOut();
			this.user = undefined;
			this.session = undefined;
		}

		//nav to the login page
		this.router.navigate(['login']);
	}

	/** Intercept all REST calls and make sure a bearer is added if it is to the backend API */
	intercept(request:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
		//if an api request
		if(request.url.startsWith(environment.apiUrl) && this.hasSession) {
			const authorizedRequest:HttpRequest<any> = request.clone({
				setHeaders: {
					Authorization: 'Bearer ' + this.session.getIdToken().getJwtToken()
				}
			});

			return next.handle(authorizedRequest);
		}

		return next.handle(request);
	}

	/** Is a session currently loaded */
	get hasSession() {
		return this.session != undefined;
	}

	/** Can a logged in page be activated or do they need to log in? */
	public static canActivateLoggedIn:CanActivateFn = () => {
		return inject(SessionService).canActivate();
	}

	public getUserName(): string {
		return this.user?.getUsername();
	}
}
