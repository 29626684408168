import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { DigitalClinicalInterview, GetDigitalClinicalInterviewsResponse, AddUpdateDigitalClinicalInterviewRequest } from "dci-manager-shared/dist/util/digital-clinical-interview";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";


/** Service for interacting with the POC backend */
@Injectable({
    providedIn: 'root'
})
export class DciManagerService {
    private _digitalClinicalInterviews: DigitalClinicalInterview[];

    constructor(private router: Router, private http: HttpClient) {
    }

    get digitalClinicalInterviews(): DigitalClinicalInterview[] {
        return this._digitalClinicalInterviews;
    }

    /** Load all digital clinical interviews from dynamo */
    async loadDigitalClinicalInterviews() {
        const response = await firstValueFrom(this.http.get<GetDigitalClinicalInterviewsResponse>(`${environment.apiUrl}/dci-manager/dci`));
        this._digitalClinicalInterviews = response.digitalClinicalInterviews;
        this._digitalClinicalInterviews.sort((a, b) => `${a.folder}/${a.name}`.localeCompare(`${b.folder}/${b.name}`));
    }
    /** create a new digital clinical interview in dynamo */
    async addDigitalClinicalInterview(name: string, folder: string, userName: string): Promise<any> {
        const request: AddUpdateDigitalClinicalInterviewRequest = {
            name: name,
            folder: folder,
            userName: userName
        };
        const response = await firstValueFrom(this.http.put<any>(`${environment.apiUrl}/dci-manager/dci`, request));
        return response;
    }
    /** create a new digital clinical interview in dynamo and upload json as from copied dci */
    async addDigitalClinicalInterviewWithJson(name: string, folder: string, userName: string, copiedId: string): Promise<any> {
        const request: AddUpdateDigitalClinicalInterviewRequest = {
            name: name,
            folder: folder,
            userName: userName
        };
        const response = await firstValueFrom(this.http.put<any>(`${environment.apiUrl}/dci-manager/dci`, request));
        try{
            const copiedJsonReponse = await firstValueFrom(this.http.get<any>(`${environment.apiUrl}/dci-manager/dci-json/${copiedId}`));
            let uploadReuest = {
                id: response.id,
                content: copiedJsonReponse
            }
            const uploadedJsonResponse = await firstValueFrom(this.http.put<any>(`${environment.apiUrl}/dci-manager/dci-json`, uploadReuest));
        } catch(ignore) {
        }

        return response;
    }

    async updateDigitalClinicalInterview(digitalClinicalInterview: DigitalClinicalInterview, userName: string) {
        const request: AddUpdateDigitalClinicalInterviewRequest = {
            id: digitalClinicalInterview.id,
            userName: userName,
            name: digitalClinicalInterview.name,
            folder: digitalClinicalInterview.folder
        };
        const response = await firstValueFrom(this.http.put<any>(`${environment.apiUrl}/dci-manager/dci`, request));
    }

    public getDigitalClinicalInterview(id: string): DigitalClinicalInterview {
        let dci = null;
        if (!this._digitalClinicalInterviews) {
            this.router.navigate(['/main']);
        }
        else {
            this._digitalClinicalInterviews.forEach(value => {
                if (value.id == id) {
                    dci = value;
                }
            });
        }
        return dci;
    }

    async getDigitalClinicalInterviewFromS3(id: string): Promise<any> {
        const response = await firstValueFrom(this.http.get<any>(`${environment.apiUrl}/dci-manager/dci-json/${id}`));
        return response;
    }

    async saveDigitalClinicalInterviewToS3(digitalClinicalInterview: DigitalClinicalInterview, dciJson: any, userName: string): Promise<any> {
        await this.updateDigitalClinicalInterview(digitalClinicalInterview, userName);
        let request = {
            id: digitalClinicalInterview.id,
            content: dciJson
        }
        const response = await firstValueFrom(this.http.put<any>(`${environment.apiUrl}/dci-manager/dci-json`, request));
        console.log("RESPONSE: ", response);
        return response
    }

    async getLabelsFromParameterStore(): Promise<any>{
        const response = await firstValueFrom(this.http.get<any>(`${environment.apiUrl}/dci-manager/labels`));
        console.log(response);
        return response;
    }
}
