import {Component} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {SessionService} from "../services/session.service";
import {ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router} from "@angular/router";
import {LoginComponent} from "../login/login.component";
import {environment} from "../../environments/environment";

/** Header of the site */
@Component({
	selector: 'app-header',
	standalone: true,
	imports: [
		MatButton
	],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent {
	loginPage:boolean = false;

	constructor(private sessionService:SessionService, private router:Router) {
		//hide button if on the login page
		router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				this.loginPage = router.isActive("/login", {
					paths: "exact",
					fragment: "ignored",
					matrixParams: "ignored",
					queryParams: "ignored"
				});
			}
		})
	}

	/** Logout the user */
	onLogout() {
		this.sessionService.logout();
	}

	public getEnvironmentDisplay(): string {
	    if(environment.envName == "production") {
	        return "";
	    }
	    return `(${environment.envName})`;
	}

	public gotoDigitalClinicalInterviewss() {
        this.router.navigate(['/main']);

	}
}
