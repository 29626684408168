import { ChangeDetectionStrategy, Component, Directive, ElementRef, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle

} from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';


@Component({
    selector: 'input-text-dialog',
    templateUrl: 'input-text-dialog.html',
    styleUrl: 'input-text-dialog.scss', 
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogTitle,
        MatDialogContent,
        FormsModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class InputTextDialog {
    inputValue: string;
    public cancelled: boolean = true;
    data = inject(MAT_DIALOG_DATA);
    readonly dialogRef = inject(MatDialogRef<InputTextDialog>);

    public cancel() {
        this.cancelled = true;
        this.dialogRef.close();
    }

    public ok() {
        this.cancelled = false;
        this.dialogRef.close();
    }

    public setValue(value: string) {
        this.inputValue = value;
        this.cancelled = true;
    }

}
