import {Component} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {SessionService} from "../services/session.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

/** Handle the login page */
@Component({
	selector: 'app-login',
	standalone: true,
	imports: [
		MatCardModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		FormsModule
	],
	templateUrl: './login.component.html',
	styleUrl: './login.component.scss'
})
export class LoginComponent {
	/** Login form */
	form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', Validators.required)
	});

	/** Should the password be hidden? */
	hidePassword:boolean = true;

	/** Is a login request currently taking place? */
	loggingIn:boolean = false;

	constructor(private sessionService:SessionService,
	            private snackbar:MatSnackBar,
	            private router:Router) {

	}

	/** Submit the login request */
	async submit() {
		if(!this.loggingIn && this.form.valid) {
			this.loggingIn = true;

			try {
				await this.sessionService.login(this.form.controls.email.value, this.form.controls.password.value);
				await this.router.navigate(['/']);
			} catch(e) {
			    console.log(e);
				this.snackbar.open("Failed to login!");
			} finally {
				this.loggingIn = false;
			}
		}
	}
}
