import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {SessionService} from "./services/session.service";

export const appConfig:ApplicationConfig = {
	providers: [
		provideRouter(routes),
		provideAnimationsAsync(),
		provideHttpClient(withInterceptorsFromDi()),

		//add session service and interceptors for handling logging in / out and maintaining session
		SessionService,
		{provide: HTTP_INTERCEPTORS, useExisting: SessionService, multi: true}
	]
};
