import { Component, OnInit } from '@angular/core';
import { SurveyCreatorModule } from "survey-creator-angular";
import { ICreatorOptions, SurveyCreatorModel } from "survey-creator-core";
import { Serializer } from "survey-core";
import { DciManagerService } from "../services/dci-manager.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DigitalClinicalInterview } from "dci-manager-shared/dist/util/digital-clinical-interview";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { SessionService } from '../services/session.service';
import { Labels } from '../util/labels';
/**
 * SurveyJS creator for surveys
 * @see https://surveyjs.io/survey-creator/documentation/get-started-angular
 */
@Component({
    selector: 'app-dci-component',
    standalone: true,
    imports: [
        SurveyCreatorModule,
        MatProgressSpinnerModule,
        CommonModule
    ],
    templateUrl: './dci.component.html',
    styleUrl: './dci.component.scss'
})
export class DciComponent implements OnInit {

    /** The survey creator model being used */
    model: SurveyCreatorModel;

    private id: string;
    private digitalClinicalInterview: DigitalClinicalInterview;
    public loading: boolean;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private dciManagerService: DciManagerService,
        private sessionService: SessionService) {
    }

    ngOnInit(): void {
        this.loading = true;
        //get the survey id from the route params and load the survey
        this.id = <string>this.route.snapshot.paramMap.get('dciId');
        this.digitalClinicalInterview = this.dciManagerService.getDigitalClinicalInterview(this.id);
        //setup default options
        const options: ICreatorOptions = {
            showLogicTab: true,
            showThemeTab: true,
            isAutoSave: true
        };

        this.addLabels();

        //initialize the creator and json
        this.model = new SurveyCreatorModel(options);
        this.model.locale = "en";
        this.model.saveSurveyFunc = (saveNo: number, callback: Function) => this.saveSurveyJson(saveNo, callback);
        this.model.autoSaveDelay = 30000;
        this.model.showSaveButton = true;
        this.loadDigitalClinicalInterviewJson();
    }

    private saveSurveyJson(saveNo: number, callback: Function) {
        this.loading = true;
        this.dciManagerService.saveDigitalClinicalInterviewToS3(this.digitalClinicalInterview, this.model.JSON, this.sessionService.getUserName())
            .then(value => {
                this.loading = false;
                callback(saveNo, true);
            });
    }

    async loadDigitalClinicalInterviewJson() {
        if (!this.digitalClinicalInterview) {
            return;
        }
        try {
            let json = await this.dciManagerService.getDigitalClinicalInterviewFromS3(this.digitalClinicalInterview.id);
            this.model.JSON = json;
            this.loading = false;
        } catch (ignore) {
        }
    }

    public getDigitalClinicalInterviewPath(): string {
        if (!this.digitalClinicalInterview) {
            return '';
        }
        return `${this.digitalClinicalInterview.folder}/${this.digitalClinicalInterview.name}`;
    }


    private addLabels() {
        Labels.labels.forEach(label => {
            let index = 0;
            label.properties.forEach(props => {
                props.visibleIndex = index++;
                Serializer.addProperty(label.type, props);
            });
        });
    }

}