import {Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {MainComponent} from "./main/main.component";
import {DciComponent} from "./manager/dci.component";
import {SessionService} from "./services/session.service";

export const routes:Routes = [
	{
		path: "login",
		title: "DCI Manager - Login",
		component: LoginComponent
	},
	{
		path: "main",
		title: "DCI Manager",
		component: MainComponent,
		canActivate: [SessionService.canActivateLoggedIn],
	},
	{
		path: "dci/:dciId",
		title: "DCI Manager",
		component: DciComponent,
		canActivate: [SessionService.canActivateLoggedIn],
	},
	{path: '**', redirectTo: 'main'}
];
